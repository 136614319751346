import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Button, Col, Input, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { useCreateChildAttendanceAsCourtesyClassMutation } from "../../api/main/childAttendances/viewModels/useCreateChildAttendanceAsCourtesyClassMutation";
import { useClassFinderClassViewModel } from "../../api/main/classFinder/viewModels/useClassFinderClassViewModel";
import { dayOfWeekDisplayName } from "../../api/main/models/constants/DayOfWeek";
import { PaymentState } from "../../api/main/models/constants/PaymentState";
import { useJoinScheduledClassMutation } from "../../api/main/scheduledClassChildren/useJoinScheduledClassMutation";
import { useJoinScheduledClassWaitingListMutation } from "../../api/main/scheduledClassChildren/useJoinScheduledClassWaitingListMutation";
import { useSwapScheduledClassChildMutation } from "../../api/main/scheduledClassChildren/useSwapScheduledClassMutation";
import { useTermForAnonymousUser } from "../../api/main/terms/useTermForAnonymousUser";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { ClassDetailsModal } from "./ClassDetailsModal";
import { ScheduledClassWithRelationships } from "./ClassFinder";
import "./classFinderClass.scss";

export interface JoinCourtesyClassMessages {
    errorMessage: string | undefined | null, warningMessage: string | undefined | null, successMessage: string | undefined | null;
}

export type TableDataItem = {
    dayOfWeek: string,
    lessonDates: Array<Date>,
    holidayDates: Array<Date>,
    changed: boolean,
};

interface ClassFinderClassProps {
    scheduledClass: ScheduledClassWithRelationships | undefined | null;
    childDateOfBirth: string | undefined | null;

    childId?: string | undefined | null;
    isForCourtesyClass?: boolean;
    courtesyClassCreditId?: string | undefined | null;
    isSwappingClass?: boolean | undefined | null;
    oldScheduledClassChildId?: string | undefined | null;
}

export const ClassFinderClass = (props: ClassFinderClassProps) => {
    const {
        scheduledClass,
        childDateOfBirth,
        childId,
        isForCourtesyClass,
        courtesyClassCreditId,
        isSwappingClass = false,
        oldScheduledClassChildId
    } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        data: {
            items: cubsInClass,
            scheduledClassChildren,
        }, refresh
    } = useClassFinderClassViewModel(scheduledClass?.id ?? '');

    const oldScheduledClassId = useMemo(() => {
        if (!scheduledClassChildren) return "";

        return scheduledClassChildren?.find(it => it.id === oldScheduledClassChildId)?.scheduledClassId;
    }, [scheduledClassChildren, oldScheduledClassChildId]);

    // Load the current term
    const {
        data: {
            model: currentTerm,
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useTermForAnonymousUser(scheduledClass?.termId);
    const isLoading = isLoadingCurrentTerm;

    // Pick data modal
    const [isPickDateModalOpen, togglePickDateModal] = useToggleState();

    // Courtesy Class - Selected date
    const [selectedDate, setSelectedDate] = useState<string | undefined>();

    // Lesson dates
    const classDates = useMemo(() => {
        if (!scheduledClass || !currentTerm) return;

        let dates = scheduledClass?.lessonDatesForCurrentTerm?.map(dateString => new Date(dateString)) ?? [];

        if (isForCourtesyClass) {
            // Is the class full, excluding childAbsences.
            const classIsFullOfCubs = !!((scheduledClass?.allChildren?.length ?? 0) >= (scheduledClass?.maximumClassSize ?? 10));
            const childAbsences = scheduledClass?.allAbsences ?? [];

            // Remove the fist and last lesson date in the term due to not being able to book a courtesy class on these dates
            dates = dates.slice(1, dates.length - 1);

            // Remove any past dates
            const today = new Date();
            dates = dates.filter(it => it >= today);

            // Remove classes that start in less than 30 minutes
            dates = dates.filter(it => {
                const now = new Date();
                const timeDifference = (it.getTime() - now.getTime()) / 60000; // Difference in minutes
                return timeDifference >= 30;
            });

            if (classIsFullOfCubs) {
                dates = dates.filter(date =>
                    childAbsences.some(absence =>
                        moment(date).isBetween(moment(absence.startDate), moment(absence.endDate), 'day', '[]')
                    )
                );
            }
        }

        // Arrange dates so that if we had dates across several months we output like: 10th, 17th, 24th May, 7th, 14th, 21st, 28th June, 5th, 12th, 19th, 26th July.
        // We can do this by grouping by month and then sorting by date.
        const months = dates.reduce((acc, curr) => {
            const monthName = moment(curr).format('MMMM');

            const monthDates = acc.find(it => it.monthName === monthName);

            if (monthDates) {
                monthDates.dates.push(curr);
            } else {
                acc.push({ monthName, dates: [curr] });
            }

            return acc;
        }, [] as { monthName: string, dates: Date[]; }[]);

        // Sort the dates
        months.forEach(it => {
            it.dates.sort((a, b) => a.getDate() - b.getDate());
        });

        return months;
    }, [scheduledClass, currentTerm, isForCourtesyClass]);

    const firstLessonDate = useMemo(() => {
        if (!scheduledClass) return null;

        return scheduledClass.lessonDatesForCurrentTerm?.[0] ?? '';
    }, [scheduledClass]);

    const lastLessonDate = useMemo(() => {
        if (!scheduledClass) return null;

        return scheduledClass.lessonDatesForCurrentTerm?.[scheduledClass.lessonDatesForCurrentTerm.length - 1] ?? '';
    }, [scheduledClass]);

    // Toggle the class info modal
    const [isClassInfoModalOpen, _toggleClassInfoModal] = useToggleState();
    const toggleClassInfoModal = useCallback(() => {
        _toggleClassInfoModal();
    }, [_toggleClassInfoModal]);

    // Does the class have space
    const hasSpace = useMemo(() => {
        return !(((scheduledClass?.allChildren?.length ?? 0) - (scheduledClass?.allAbsences?.length ?? 0)) >= (scheduledClass?.maximumClassSize ?? 10));
    }, [scheduledClass]);

    const classIsNowFull = useMemo(() => {
        return (cubsInClass?.length ?? 0) >= (scheduledClass?.maximumClassSize ?? 10);
    }, [cubsInClass, scheduledClass]);

    // Registering for a Class - Anon
    const handleRegisterForClass = useCallback((scheduledClass: ScheduledClassWithRelationships | null, isWaitingList: boolean) => {
        if (!scheduledClass) {
            return;
        }

        if (isWaitingList) {
            navigate(`join-class-waiting-list/${scheduledClass.id}/${childDateOfBirth}`);
            return;
        }

        navigate(`enrol-in-class/${scheduledClass.id}/${childDateOfBirth}`);

    }, [childDateOfBirth, navigate]);

    const [warningMessage, setWarningMessage] = useState<string | undefined | null>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined | null>(undefined);

    // Join a child to the class.
    const [joinScheduledClassMutation, { errors: joinScheduledClassMutationErrors, }] = useJoinScheduledClassMutation();
    const [joinClass, { isExecuting: isJoiningClass, errors: joinClassErrors }] = useAsyncCallback(async () => {
        if (!scheduledClass || !childId) {
            return;
        }

        await refresh();

        if (classIsNowFull) {
            setWarningMessage(t('classFinderClass.warningMessage.classFull', 'We\'re sorry but the place in the class is no longer available'));

            setTimeout(() => {
                setWarningMessage(undefined);

                window.location.reload();
            }, 2000);

            return;
        }

        // Join the class.
        const result = await joinScheduledClassMutation(childId, scheduledClass.id);
        if (!!result?.payment) {
            if (result.payment.paymentState === PaymentState.Paid) {
                // Already paid so go back to the list of classes.
                navigate(`/my/children/details/${childId}?tab=classes`);
            }

            // We need to pay for this, so go the checkout screen.
            navigate(`/my/checkout/${result.payment.id}`);
        } else {
            // We got an error so stay on this screen and let the error show.
        }
    }, [scheduledClass, childId, joinScheduledClassMutation, cubsInClass, refresh, classIsNowFull]);

    // Join a child to the waiting list.
    const [joinScheduledClassWaitingListMutation, { errors: joinScheduledClassWaitingListMutationErrors, }] = useJoinScheduledClassWaitingListMutation();
    const [joinWaitingList, { isExecuting: isJoiningWaitingList, errors: joinWaitingListErrors }] = useAsyncCallback(async () => {
        if (!scheduledClass || !childId) {
            return;
        }

        // Join the class waiting list.
        const result = await joinScheduledClassWaitingListMutation(childId, scheduledClass.id, isSwappingClass ? true : false);
        if (!!result?.scheduledClassChild) {
            if (result.scheduledClassChild.isOnWaitingList) {
                // They are now on the waiting list.
                navigate(`/my/children/details/${childId}?tab=waitingLists`);
            } else {
                // They already had a non-waiting list entry, so take them to the class list.
                navigate(`/my/children/details/${childId}?tab=classes`);
            }
        } else {
            // We got an error so stay on this screen and let the error show.
        }
    }, [scheduledClass, childId, joinScheduledClassWaitingListMutation]);

    // Change to this class.
    const [swapScheduledClassMutation, { errors: swapScheduledClassMutationErrors, }] = useSwapScheduledClassChildMutation();
    const [swapClass, { isExecuting: isChangingClass, errors: swapClassErrors }] = useAsyncCallback(async () => {
        if (!scheduledClass || !childId || !oldScheduledClassChildId) {
            return;
        }

        // Swap the class.
        const result = await swapScheduledClassMutation(oldScheduledClassChildId, scheduledClass.id, false);
        if (!!result?.success) {
            setSuccessMessage(t('swapChild.success.message', 'Cub has been moved to {{dayOfWeek}} {{startTimeHours, 00}}:{{startTimeMinutes, 00}} {{locationName}}', { dayOfWeek: dayOfWeekDisplayName(scheduledClass?.dayOfWeek ?? 0, t), startTimeHours: scheduledClass?.startTimeHours, startTimeMinutes: scheduledClass?.startTimeMinutes, locationName: scheduledClass?.classLocation?.name, }));
        } else {
            // We got an error so stay on this screen and let the error show.
            setWarningMessage(result.errorMessage);

        }
    }, [scheduledClass, childId, swapScheduledClassMutation, oldScheduledClassChildId]);

    const [joinCourtesyClassMessages, setJoinCourtesyClassMessages] = useState<JoinCourtesyClassMessages>({ errorMessage: undefined, warningMessage: undefined, successMessage: undefined });

    // Join a child to the class using a CourtesyClassCredit
    const [joinAsCourtesyClassMutation, { errors: createChildAttendanceAsCourtesyClassErrorsMutationErrors }] = useCreateChildAttendanceAsCourtesyClassMutation();
    const [joinCourtesyClass, { isExecuting: isJoiningCourtesyClass, errors: joinCourtesyClassErrors }] = useAsyncCallback(async () => {
        if (!scheduledClass?.id || !courtesyClassCreditId || !childId || !selectedDate) {
            return;
        }

        const response = await joinAsCourtesyClassMutation(childId, scheduledClass?.id, courtesyClassCreditId, selectedDate);

        // Set the response message
        setJoinCourtesyClassMessages({
            warningMessage: response.warningMessage,
            errorMessage: response.errorMessage,
            successMessage: response.success ? t('classFinderClass.success.message', 'Child has been added to the register') : undefined
        });

        // If the join was successful then navigate back to the child, after a short delay
        if (response.success) {
            setTimeout(() => {
                navigate(`/my/children/details/${childId}?tab=classes`);
            }, 2000);
        }

    }, [scheduledClass, courtesyClassCreditId, joinScheduledClassMutation, childId, selectedDate]);

    const gotoMyClasses = useCallback(() => {
        navigate(`/my/children/details/${childId}?tab=classes`);
    }, [navigate, childId]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                joinScheduledClassMutationErrors,
                joinClassErrors,
                joinScheduledClassWaitingListMutationErrors,
                joinWaitingListErrors,
                currentTermLoadErrors,
                createChildAttendanceAsCourtesyClassErrorsMutationErrors,
                joinCourtesyClassErrors,
                swapScheduledClassMutationErrors, swapClassErrors
            ]} />

            <ConditionalFragment showIf={!!warningMessage}>
                <Row>
                    <Alert color="warning">
                        {warningMessage}
                    </Alert>
                </Row>
            </ConditionalFragment>

            <ConditionalFragment showIf={!!successMessage}>
                <Row>
                    <Alert color="success">
                        <Row>
                            <Col>
                                {successMessage}
                            </Col>

                            <Col>
                                <Button color="primary" onClick={() => gotoMyClasses()}>{t('common.myClasses', 'My Classes')}</Button>
                            </Col>
                        </Row>
                    </Alert>
                </Row>
            </ConditionalFragment>

            <Row className="class-finder-location-day-card-body-class">
                <ConditionalFragment showIf={!!isLoading}>
                    <LoadingIndicator />
                </ConditionalFragment>

                <Col style={{
                    minWidth: '20%',
                    maxWidth: '20%',
                }} className="class-finder-location-day-card-body-class-time" >
                    {t('classFinderClass.startTime.formmated.noDay', '{{hours, 00}}:{{minutes, 00}}', { hours: scheduledClass?.startTimeHours, minutes: scheduledClass?.startTimeMinutes })}
                </Col>

                <Col className="class-finder-location-day-card-body-class-stage">
                    {scheduledClass?.classStage?.name} {scheduledClass?.classSubStage?.name}
                    <FontAwesomeIcon style={{ paddingLeft: '4px' }} icon="info-circle" onClick={() => toggleClassInfoModal()} />
                </Col>

                <Col xs={"auto"}>
                    <ConditionalFragment showIf={!!hasSpace}>
                        <ConditionalFragment showIf={!childId}>
                            <Button className="class-finder-location-day-card-body-class-button" color="primary" size="sm" onClick={() => handleRegisterForClass(scheduledClass ? scheduledClass : null, false)}>{t('common.bookNow', 'Book now')}</Button>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!childId && !isForCourtesyClass && !isSwappingClass}>
                            <ButtonAsync className="class-finder-location-day-card-body-class-button" color="primary" size="sm" onClick={() => joinClass()}
                                isExecuting={isJoiningClass} executingChildren={<><Spinner size="sm" /><> </>{t('common.ellipsis', '...')}</>}
                            >
                                {t('common.bookNow', 'Book now')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!childId && !isForCourtesyClass && !!isSwappingClass}>
                            <ButtonAsync className="class-finder-location-day-card-body-class-button" color="primary" size="sm" onClick={() => swapClass()}
                                isExecuting={isChangingClass} executingChildren={<><Spinner size="sm" /><> </>{t('common.ellipsis', '...')}</>}
                            >
                                {t('common.requestNow', 'Request now')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!childId && !!isForCourtesyClass}>
                            <Button className="class-finder-location-day-card-body-class-button" color="primary" size="sm" onClick={() => togglePickDateModal()}>
                                {t('common.pickDate', 'Pick date')}
                            </Button>
                        </ConditionalFragment>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!hasSpace}>
                        <ConditionalFragment showIf={!childId}>
                            <Button className="class-finder-location-day-card-body-class-button" color="secondary" size="sm" onClick={() => handleRegisterForClass(scheduledClass ? scheduledClass : null, true)}>{t('common.waitlist', 'Waitlist')}</Button>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!childId}>
                            <ButtonAsync className="class-finder-location-day-card-body-class-button" color="secondary" size="sm" onClick={() => joinWaitingList()}
                                isExecuting={isJoiningWaitingList} executingChildren={<><Spinner size="sm" /><> </>{t('common.ellipsis', '...')}</>}
                            >
                                {t('common.waitlist', 'Waitlist')}
                            </ButtonAsync>
                        </ConditionalFragment>
                    </ConditionalFragment>
                </Col>
            </Row>

            <ClassDetailsModal
                isOpen={isClassInfoModalOpen}
                toggle={toggleClassInfoModal}
                scheduledClass={scheduledClass} />

            <StyledModal
                isOpen={isPickDateModalOpen}
                toggle={() => { togglePickDateModal(); setSelectedDate(undefined); }}
                size="sm">
                <ModalHeader toggle={() => { togglePickDateModal(); setSelectedDate(undefined); }}>
                    {t('classFinderClass.pickDateModal.header', 'Select a suitable date')}
                </ModalHeader>

                <ModalBody>
                    <ConditionalFragment showIf={
                        !!joinCourtesyClassMessages.errorMessage ||
                        !!joinCourtesyClassMessages.warningMessage ||
                        !!joinCourtesyClassMessages.successMessage
                    }>
                        <Alert color={
                            joinCourtesyClassMessages.errorMessage ? 'danger' :
                                joinCourtesyClassMessages.warningMessage ? 'warning' : 'success'
                        }>
                            <ConditionalFragment showIf={!!joinCourtesyClassMessages.successMessage}>
                                {joinCourtesyClassMessages.successMessage}
                            </ConditionalFragment>

                            <ConditionalFragment showIf={!!joinCourtesyClassMessages.warningMessage}>
                                {joinCourtesyClassMessages.warningMessage}
                            </ConditionalFragment>

                            <ConditionalFragment showIf={!!joinCourtesyClassMessages.errorMessage}>
                                {joinCourtesyClassMessages.errorMessage}
                            </ConditionalFragment>
                        </Alert>
                    </ConditionalFragment>

                    <Row>
                        <Alert color="warning">
                            {t('classFinderClass.courtesyClassesFirstAndLast.message', 'Courtesy classes can\'t be booked on the first week of a term {{firstLessonDate, DD/MM/YYYY}} or on the last week of a term {{lastLessonDate, DD/MM/YYYY}}', { firstLessonDate: moment(firstLessonDate), lastLessonDate: moment(lastLessonDate) })}
                        </Alert>
                    </Row>

                    <Row>
                        {classDates?.map((month, index) => (
                            <Col key={index}>
                                <h3>{month.monthName}</h3>
                                {month.dates.map((date, dateIndex) => (
                                    <Row key={dateIndex}>
                                        <Col xs={1}>
                                            <Input type="checkbox" checked={selectedDate === date.toISOString()} onClick={() => setSelectedDate(date.toISOString())} />
                                        </Col>
                                        <Col>
                                            {moment(date).format('Do')}
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        ))}
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <ButtonAsync color="primary" disabled={!selectedDate} onClick={() => joinCourtesyClass()}
                        isExecuting={isJoiningCourtesyClass} executingChildren={<><Spinner size="sm" /><> </>{t('common.checking', 'Checking...')}</>}
                    >
                        {t('common.joinCourtesyClass', 'Join courtesy class')}
                    </ButtonAsync>
                    <Button color="primary" outline onClick={() => { togglePickDateModal(); setSelectedDate(undefined); }}>{t('common.cancel', 'Cancel')}</Button>
                </ModalFooter>
            </StyledModal>
        </>
    );
};