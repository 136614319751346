import { useEffect } from "react";
import { useAccountService } from "../../api/account";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "../../shared/useURLSearchParams";

/**
 * LoginWithOneTimeCode - Allows an admin to emualte a user. The process for emulating a user is triggered from UsersList.tsx
 */
export const LoginWithOneTimeCode = () => {
    const accountService = useAccountService();
    const navigate = useNavigate();

    // Get the params
    const {
        email,
        code,
    } = useSearchParams();

    useEffect(() => {
        if (!email || !code) return;

        const loginWithOneTimeCode = async () => {
            const result = await accountService.loginWithOneTimeCode({ email, code });

            if (result) {
                navigate(result.returnUrl);
            }
        };

        loginWithOneTimeCode();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [email, code, accountService, navigate]);

    // Redner the UI
    // 
    return (
        <></>
    );
};