import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { queryMessageFieldsFragment } from '../../models/QueryMessage';
import { AdminQueryMessagesForAllMembersViewModelQuery, AdminQueryMessagesForAllMembersViewModelQueryVariables } from '../../generated/graphql';
import { queryMessageTypeFieldsFragment } from '../../models/QueryMessageType';

export interface AdminQueryMessagesForAllMembersViewModelOptions extends AsyncLoadPagedOptions
{
    numberOfMonths?: number | undefined | null,    
}

/**
 * Get a list of QueryMessages from the api.
 * @param options
 */
export function useAdminQueryMessagesForAllMembersViewModel(options: AdminQueryMessagesForAllMembersViewModelOptions = {}): AsyncLoadPagedResult<AdminQueryMessagesForAllMembersViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<AdminQueryMessagesForAllMembersViewModelQuery, AdminQueryMessagesForAllMembersViewModelQueryVariables>(
        gql`
        query adminQueryMessagesForAllMembersViewModelQuery ($offset: Int, $first: Int, $numberOfMonths: Int) {
            items: queryMessages (offset: $offset, first: $first, numberOfMonths: $numberOfMonths) {
                ...queryMessageFields

                member {
                    photoBlobReferenceId

                    user {
                        id

                        profile {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }

            queryMessageTypes {
                ...queryMessageTypeFields
            }

            profiles { 
                id
                userId
                firstName
                lastName
                archived
                primaryEmail
                secondaryEmail
                primaryPhone
                secondaryPhone
                addressLine1
                addressLine2
                city
                county
                postcode
                country
                genderId
                isStaff
                isMember
                oneTimeEmulationCode
            }
        }

        ${queryMessageFieldsFragment}
        ${queryMessageTypeFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                numberOfMonths: options.numberOfMonths ?? 0,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<AdminQueryMessagesForAllMembersViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            queryMessageTypes: [...(prevResults?.queryMessageTypes ?? []), ...(newResults?.queryMessageTypes ?? [])],
            profiles: [...(prevResults?.profiles ?? []), ...(newResults?.profiles ?? [])],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}