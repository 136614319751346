import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Alert, Button, Col, Row, Spinner } from "reactstrap";
import { useConsumeMembersChildrenViewModel } from "../../api/main/children/viewModels/useConsumeMembersChildrenViewModel";
import { Child } from "../../api/main/models/Child";
import { ClassLocation } from "../../api/main/models/ClassLocation";
import { ScheduledClass } from "../../api/main/models/ScheduledClass";
import { CourtesyClassCredit } from "../../api/main/models/CourtesyClassCredit";
import { ScheduledClassChild } from "../../api/main/models/ScheduledClassChild";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { MembersChildrenItem } from "./MembersChildrenItem";
import "./membersChildrenList.scss";
import { AddChildComponent } from "./AddChildComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useCreateMemberForStaffMutation } from "../../api/main/members/useCreateMemberForStaffMutation";
import { useAsyncCallback } from "react-use-async-callback";
import { NoticeBoard } from "../noticeBoard/NoticeBoard";
import { useNavigate } from "react-router-dom";

export interface MembersChildrenListWithScheduledClasses extends Child {
    courtesyClassCredits: Array<CourtesyClassCredit>,
    scheduledClassChildren: Array<ScheduledClassChildrenWithScheduledClass>,
}

export interface ScheduledClassChildrenWithScheduledClass extends ScheduledClassChild {
    scheduledClass: ScheduledClassWithClassLocation;
}

export interface ScheduledClassWithClassLocation extends ScheduledClass {
    classLocation?: ClassLocation;
    lessonDatesForCurrentTerm?: Array<string>;
}

/**
 * MyArea for managing Children
 */
export const MembersChildrenList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the current user Id
    const {
        data: {
            model
        }, isLoading: isLoadingUser, errors: userLoadErrors,
    } = useCurrentUserProfile();

    // Load the data
    const {
        data: {
            items: _items,
            scheduledClassStaffs,
            genders,
            classStages,
            classSubStages,
            classTypes,
            terms,
        }, isLoading: _isLoading, errors: loadErrors,
        refresh,
    } = useConsumeMembersChildrenViewModel(model?.id);
    const isLoading = _isLoading || isLoadingUser;

    // Filters
    const items = useMemo(() => {
        let ret = (_items as MembersChildrenListWithScheduledClasses[] || []);

        return ret;
    }, [_items]);

    // Activate staff members without member records so they become members.
    const [hasActivated, setHasActivated] = useState<boolean>(false); // To keep the UI up to date, we have to know if actiateAsMember has been called so we can start showing children even though isMember may not show as set on the profile due to multiple levels of caching.
    const [createMemberForStaffMutation, { errors: createMemberForStaffMutationErrors }] = useCreateMemberForStaffMutation();
    const [activateAsMember, { isExecuting: isActivatingAsMember, errors: activateAsMemberErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        await createMemberForStaffMutation(model.id);
        setHasActivated(true);
        refresh();
    }, [createMemberForStaffMutation, model, refresh, setHasActivated]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <NoticeBoard />
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>{t('membersChildrenList.title', 'My Bear Cubs')}</h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        userLoadErrors,
                        activateAsMemberErrors, createMemberForStaffMutationErrors,
                    ]}
                />

                <ConditionalFragment showIf={model?.primaryPhone === ''}>
                    <Alert color="info" style={{ textAlign: 'center' }}>
                        <p>
                            {t('membersChildrenList.noPrimaryPhone', 'Please add a primary phone number to your profile to receive SMS notifications.')}
                        </p>

                        <Button color="secondary" onClick={() => navigate('/profile/details')}>
                            <FontAwesomeIcon icon="edit" />
                            <> </>
                            {t('membersChildrenList.editMyProfile.button.text', 'Edit my profile')}
                        </Button>
                    </Alert>
                </ConditionalFragment>

                {/* If we are logged in a staff member who hasn't got a member record, offer to make them one as they can't add children until they are a member */}
                {
                    !!model && !model.isMember && !hasActivated ? (
                        <div className="members-children-list-staff-is-not-member">
                            <div className="members-children-list-staff-is-not-member-icon">
                                <FontAwesomeIcon icon="user-gear" />
                            </div>
                            <div className="members-children-list-staff-is-not-member-text">
                                {t('membersChildrenList.staffIsNotMember.text', 'Your staff account is not yet registered as a Mama / Papa Bear for self service of your own cubs on the portal.')}
                            </div>
                            <div className="members-children-list-staff-is-not-member-actions">
                                <ButtonAsync color="primary"
                                    isExecuting={isActivatingAsMember} executingChildren={<><Spinner size="sm" /><> </>{t('membersChildrenList.staffIsNotMember.button.excutingText', 'Activating...')}</>}
                                    onClick={activateAsMember}
                                >
                                    {t('membersChildrenList.staffIsNotMember.button.text', 'Activate my staff account as a Mama / Papa Bear')}
                                </ButtonAsync>
                            </div>
                        </div>
                    ) : (
                        // Otherwise show the children list.
                        <div className="members-children-list">
                            {items?.map((item, index) => (
                                <MembersChildrenItem
                                    model={item}
                                    genders={genders}
                                    key={item.id}
                                    scheduledClassStaffs={scheduledClassStaffs}
                                    classTypes={classTypes}
                                    classStages={classStages}
                                    classSubStages={classSubStages}
                                    hasMultipleChildren={items?.length > 1}
                                    childNumber={index + 1}
                                    terms={terms}
                                />
                            ))}

                            <ConditionalFragment showIf={!isLoading}>
                                <AddChildComponent
                                    isFirstChild={!items?.length}
                                    memberProfileId={model?.id} />
                            </ConditionalFragment>
                        </div>
                    )
                }
            </MainContainer>
        </>
    );
};