import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ConsumeScheduledClassToAddHolidayOrSicknessSupportingDataQuery, ConsumeScheduledClassToAddHolidayOrSicknessSupportingDataQueryVariables } from '../../generated/graphql';
import { childAbsenceFieldsFragment } from "../../models/ChildAbsence";
import { courtesyClassCreditFieldsFragment } from "../../models/CourtesyClassCredit";

export function useConsumeScheduledClassToAddHolidayOrSicknessSupportingData(termId: string | undefined | null, childId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ConsumeScheduledClassToAddHolidayOrSicknessSupportingDataQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<ConsumeScheduledClassToAddHolidayOrSicknessSupportingDataQuery, ConsumeScheduledClassToAddHolidayOrSicknessSupportingDataQueryVariables>(
        gql`
    query consumeScheduledClassToAddHolidayOrSicknessSupportingData ($termId: ID!, $childId: ID!) {
        courtesyClassCredits (termId: $termId, childId: $childId) {
            ...courtesyClassCreditFields
        }

        childAbsences (termId: $termId, childId: $childId) {
            ...childAbsenceFields
        }
    }

    ${courtesyClassCreditFieldsFragment}
    ${childAbsenceFieldsFragment}
    `,
        {
            variables: {
                termId: termId ?? Guid.empty,
                childId: childId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};