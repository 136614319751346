import { useLazyQuery } from "@apollo/client";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import gql from "graphql-tag";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { useCallback, useEffect, useMemo } from "react";
import { PaymentsListSupportingDataQuery } from '../../generated/graphql';
import { childFieldsFragment } from "../../models/Child";
import { termFieldsFragment } from "../../models/Term";

/**
 * Get the supporting data needed for the PaymentsList component
 * @param id 
 * @param options
 */
export function usePaymentsListSupportingData(options: AsyncLoadOptions = {}): AsyncLoadResult<PaymentsListSupportingDataQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<PaymentsListSupportingDataQuery>(
        gql`
            query paymentsListSupportingData {
                children {
                    ...childFields
                }

                profiles {
                    id
                    userId
                    firstName
                    lastName
                    archived
                    primaryEmail
                    secondaryEmail
                    primaryPhone
                    secondaryPhone
                    addressLine1
                    addressLine2
                    city
                    county
                    postcode
                    country
                    genderId
                    isStaff
                    isMember
                    oneTimeEmulationCode
                }

                terms {
                    ...termFields
                }
            }

        ${childFieldsFragment}
        ${termFieldsFragment}
        `,
        {
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}