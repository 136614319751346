import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Input, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { dayOfWeekDisplayName } from "../../api/main/models/constants/DayOfWeek";
import { useScheduledClassesForStopReenrolmentsViewModel } from "../../api/main/scheduledClasses/viewModels/useScheduledClassesForStopReenrolmentsViewModel";
import { useNextTerm } from "../../api/main/terms/useNextTerm";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { ClassScheduleFilterState } from "../scheduledClass/schedule/ClassSchedule";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";

export interface AddToReenrolmentModalCloseEventArgs {
    selectedIds: Array<string>,
    cancelled: boolean,
}

export interface AddToReenrolmentModalProps {
    toggle: () => void,
    onClose: (event: AddToReenrolmentModalCloseEventArgs) => Promise<void>,
    isSaving: boolean,
};

export const AddToStopReenrolmentModal = (props: AddToReenrolmentModalProps) => {
    const {
        toggle,
        onClose,
        isSaving,
    } = props;
    const { t } = useTranslation();

    // Load data.
    const {
        data: {
            items: _allItems,
            classTypes,
            classLocations,
            classStages,
            classSubStages,
            terms
        },
        isLoading: isLoadingData, errors: loadingErrors } = useScheduledClassesForStopReenrolmentsViewModel({ pageSize: undefined, });

    // Load the next term
    const {
        data: {
            model: nextTerm
        }, isLoading: isLoadingNextTerm, errors: nextTermLoadErrors
    } = useNextTerm();
    const isLoading = isLoadingData || isLoadingNextTerm;

    // Combine all the loaded data so each item includes its related details.
    const allItems = useMemo(() => _allItems?.filter(it => it.reenrolmentProcessedDate)?.map(item => {
        const classType = classTypes?.find(it => it.id === item.classTypeId);
        const classLocation = classLocations?.find(it => it.id === item.classLocationId);
        const currentClassStage = classStages?.find(it => it.id === item.currentClassStageId);
        const currentClassSubStage = classSubStages?.find(it => it.id === item.currentClassSubStageId);
        const nextClassStage = classStages?.find(it => it.id === item.nextClassStageId);
        const nextClassSubStage = classSubStages?.find(it => it.id === item.nextClassSubStageId);
        const term = terms?.find(it => it.id === item.termId);

        return {
            ...item,

            classType,
            classLocation,
            currentClassStage,
            currentClassSubStage,
            nextClassStage,
            nextClassSubStage,
            term
        };
    }), [_allItems, classTypes, classLocations, classStages, classSubStages, terms]);

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useState<ClassScheduleFilterState>({});
    const changeFilterState = useCallback((changes: Partial<ClassScheduleFilterState>) => setFilterState(prevState => ({
        ...prevState,
        ...changes,
    })), [setFilterState]);

    // Filter the items that can be filtered by other filter choices.
    const classStagesForFilter = useMemo(() => classStages?.filter(item => item.classTypeId === filterState.classTypeId), [classStages, filterState]);
    const classSubStagesForFilter = useMemo(() => classSubStages?.filter(item => item.classStageId === filterState.classStageId), [classSubStages, filterState]);

    // Filter the items based on re-enrollment status
    const [reenrollmentStatusFilter, setReenrollmentStatusFilter] = useState<'all' | 'reenrolled' | 'notReenrolled'>('all');

    // Filter by the search.
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                (item.classType?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.classLocation?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.currentClassStage?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.currentClassSubStage?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || dayOfWeekDisplayName(item.dayOfWeek, t).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || t('closeReenrolmentModal.startTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.startTimeHours, minutes: item.startTimeMinutes }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || t('closeReenrolmentModal.endTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.endTimeHours, minutes: item.endTimeMinutes }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.term?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        if (filterState.classTypeId) {
            ret = ret?.filter(item => item.classTypeId === filterState.classTypeId);
        }

        if (filterState.classStageId) {
            ret = ret?.filter(item => item.currentClassStageId === filterState.classStageId);
        }

        if (filterState.classSubStageId) {
            ret = ret?.filter(item => item.currentClassSubStageId === filterState.classSubStageId);
        }

        if (filterState.classLocationId) {
            ret = ret?.filter(item => item.classLocationId === filterState.classLocationId);
        }

        // We only want to see classes for next term
        if (nextTerm && nextTerm?.id) {
            ret = ret?.filter(item => item.termId === nextTerm.id);
        }

        if (filterState.dayOfWeek) {
            ret = ret?.filter(item => item.dayOfWeek.toString() === filterState.dayOfWeek);
        }

        if (reenrollmentStatusFilter === 'reenrolled') {
            ret = ret?.filter(item => !!item.reenrolmentProcessedDate);
        }

        if (reenrollmentStatusFilter === 'notReenrolled') {
            ret = ret?.filter(item => !item.reenrolmentProcessedDate);
        }

        return ret;

    }, [allItems, t, search, nextTerm, filterState, reenrollmentStatusFilter]);

    // Manage the selected classes
    const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
    const toggleSelection = useCallback((id: string) => {
        setSelectedIds(prevState => {
            const existing = prevState.find(it => it === id);
            if (existing) {
                return prevState.filter(it => it !== id);
            }

            return [
                ...prevState,
                id
            ];
        });
    }, [setSelectedIds]);
    const isSelected = useCallback((id: string) => !!selectedIds.find(it => it === id), [selectedIds]);

    // Close the modal and return the results
    const closeModal = useCallback(async (event?: { selectedIds: Array<string>, cancelled?: boolean, }) => {
        if (onClose) {
            const thisSelectedIds = event?.selectedIds ?? selectedIds;

            const externalEvent: AddToReenrolmentModalCloseEventArgs = {
                ...(event ?? {}),

                selectedIds: thisSelectedIds,
                cancelled: event?.cancelled ?? false,
            };
            await onClose(externalEvent);
        }

        // Close the modal
        toggle();
    }, [selectedIds, toggle, onClose,]);

    // Add all SwimCubs classes to the re-enrolment list
    const addAllClasses = useCallback((classType?: string) => {
        let classes = [];

        if (classType) {
            classes = items.filter(item => item.classType?.name === classType);
        } else {
            classes = items;
        }

        setSelectedIds(classes.map(item => item.id));
    }, [items]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                loadingErrors, nextTermLoadErrors
            ]} />

            <ModalHeader>
                <Row>
                    <h3>
                        {t('closeReenrolmentModal.modal.header', 'Close re-enrolments for {{termName}}', { termName: nextTerm?.name })}
                    </h3>
                    <p>
                        {t('closeReenrolmentModal.modal.subHeading', 'select classes:')}
                    </p>
                </Row>

            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>
                    <Col xs="auto">
                        <Input type="select" value={reenrollmentStatusFilter ?? 'all'} onChange={e => setReenrollmentStatusFilter(e.currentTarget.value as any)}>
                            <option value="all">{t('closeReenrolmentModal.filter.reenrollmentStatus.all', 'All classes')}</option>
                            <option value="reenrolled">{t('closeReenrolmentModal.filter.reenrollmentStatus.reenrolled', 'Re-enrolled')}</option>
                            <option value="notReenrolled">{t('closeReenrolmentModal.filter.reenrollmentStatus.notReenrolled', 'Not re-enrolled')}</option>
                        </Input>
                    </Col>
                </Row>

                <Row style={{ margin: '1rem 0' }}>
                    <Col xs="auto">
                        <Input type="select" value={filterState.dayOfWeek ?? ''} onChange={e => changeFilterState({ dayOfWeek: e.currentTarget.value, })}>
                            <option value="">{t('closeReenrolmentModal.filter.dayOfWeek.all', '(All days)')}</option>
                            <option value="0">{t('closeReenrolmentModal.filter.dayOfWeek.sunday', 'Sunday')}</option>
                            <option value="1">{t('closeReenrolmentModal.filter.dayOfWeek.monday', 'Monday')}</option>
                            <option value="2">{t('closeReenrolmentModal.filter.dayOfWeek.tuesday', 'Tuesday')}</option>
                            <option value="3">{t('closeReenrolmentModal.filter.dayOfWeek.wednesday', 'Wednesday')}</option>
                            <option value="4">{t('closeReenrolmentModal.filter.dayOfWeek.thursday', 'Thursday')}</option>
                            <option value="5">{t('closeReenrolmentModal.filter.dayOfWeek.friday', 'Friday')}</option>
                            <option value="6">{t('closeReenrolmentModal.filter.dayOfWeek.saturday', 'Saturday')}</option>
                        </Input>
                    </Col>
                    <Col>
                        <Input type="select" value={filterState.classTypeId ?? ''} onChange={e => changeFilterState({ classTypeId: e.currentTarget.value, classStageId: '', classSubStageId: '', })}>
                            <option value="">{t('closeReenrolmentModal.filter.classTypes.all', '(All activities)')}</option>
                            {
                                classTypes?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Input>
                    </Col>
                    <ConditionalFragment showIf={!!filterState.classTypeId}>
                        <Col>
                            <Input type="select" value={filterState.classStageId ?? ''} onChange={e => changeFilterState({ classStageId: e.currentTarget.value, classSubStageId: '', })}>
                                <option value="">{t('closeReenrolmentModal.filter.classStages.all', '(All class types)')}</option>
                                {
                                    classStagesForFilter?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Input>
                        </Col>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!filterState.classStageId}>
                        <Col>
                            <Input type="select" value={filterState.classSubStageId ?? ''} onChange={e => changeFilterState({ classSubStageId: e.currentTarget.value })}>
                                <option value="">{t('closeReenrolmentModal.filter.classSubStages.all', '(All stages)')}</option>
                                {
                                    classSubStagesForFilter?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Input>
                        </Col>
                    </ConditionalFragment>
                    <Col>
                        <Input type="select" value={filterState.classLocationId ?? ''} onChange={e => changeFilterState({ classLocationId: e.currentTarget.value })}>
                            <option value="">{t('closeReenrolmentModal.filter.classLocations.all', '(All locations)')}</option>
                            {
                                classLocations?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Input>
                    </Col>
                </Row>

                <Row>
                    <Col></Col>

                    <Col xs="auto">
                    </Col>
                </Row>

                <Row className="text-center">
                    <Col></Col>

                    <Col xs="auto">
                        <ButtonGroup>
                            <Button color="primary" outline onClick={() => addAllClasses()}>{t('closeReenrolmentModal.addAllClasses', 'Add all classes')}</Button>
                            <Button color="primary" outline onClick={() => addAllClasses('Swim Cubs')}>{t('closeReenrolmentModal.addAllSwimCubsClasses', 'Add all Swim Cubs classes')}</Button>
                            <Button color="primary" outline onClick={() => addAllClasses('Music Cubs')}>{t('closeReenrolmentModal.addAllMusicCubsClasses', 'Add all Music Cubs classes')}</Button>
                            <Button color="primary" outline onClick={() => setSelectedIds([])}>{t('closeReenrolmentModal.removeAllClasses', 'Remove all classes')}</Button>
                        </ButtonGroup>
                    </Col>

                    <Col></Col>
                </Row>

                <CardsOrTable
                    viewMode={"table"}
                    items={items}
                    tableHeadings={[
                        t('closeReenrolmentModal.selected.heading', 'Selected'),
                        t('closeReenrolmentModal.dayOfWeek.heading', 'Day'),
                        t('closeReenrolmentModal.startTime.heading', 'Start time'),
                        t('closeReenrolmentModal.endTime.heading', 'End time'),
                        t('closeReenrolmentModal.classLocationId.heading', 'Location'),
                        t('closeReenrolmentModal.classTypeId.heading', 'Activity'),
                        t('closeReenrolmentModal.currentClassStageId.heading', 'Class type'),
                        t('closeReenrolmentModal.currentClassSubStageId.heading', 'Stage'),
                        t('closeReenrolmentModal.costPerLesson.term', 'Cost per term'),
                        t('closeReenrolmentModal.enrolledDate.heading', 'Date of enrolment'),

                    ]}
                    columns={[
                        // Selected
                        (item, view) => {
                            const itemIsSelected = isSelected(item.id);

                            return (
                                <>
                                    <ButtonGroup>
                                        <Button color="primary" outline={!itemIsSelected} onClick={() => toggleSelection(item.id)} >
                                            {
                                                itemIsSelected ? t('addToReenrolmentModal.class.selected', 'Selected') : t('addToReenrolmentModal.class.select', 'Select')
                                            }
                                        </Button>
                                        <ConditionalFragment showIf={itemIsSelected}>
                                            <Button color="primary" outline={!isSelected} onClick={() => toggleSelection(item.id)}>
                                                <FontAwesomeIcon icon="times" />
                                            </Button>
                                        </ConditionalFragment>
                                    </ButtonGroup>
                                </>
                            );
                        },

                        // Day
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return dayOfWeekDisplayName(item.dayOfWeek, t);
                        },

                        // Start time
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('closeReenrolmentModal.startTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.startTimeHours, minutes: item.startTimeMinutes });
                        },

                        // End time
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('closeReenrolmentModal.endTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.endTimeHours, minutes: item.endTimeMinutes });
                        },

                        // Location
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.classLocation?.name;
                        },

                        // Activity -> formerly Class type
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.classType?.name;
                        },

                        // Stage
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.currentClassStage?.name ?? '';
                        },


                        // Stage -> formerly Sub stage
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.currentClassSubStage?.name ?? '';
                        },

                        // Cost per term.
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.money.euro', '€{{value, 0.00}}', { value: item.costPerLesson * item.lessonsPerTerm });
                        },

                        // Date of enrolment
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.reenrolmentProcessedDate ? t('common.dateTime', '{{dateTime, DD/MM/YYYY HH:mm}}', { dateTime: moment(item.reenrolmentProcessedDate) }) : 'Not re-enrolled';
                        }
                    ]}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
            </ModalBody>

            <ModalFooter>
                <ButtonAsync color="primary" onClick={() => closeModal({ selectedIds: selectedIds, cancelled: false, })} isExecuting={isSaving ?? false}
                    executingChildren={<><Spinner size="sm" /> {t('common.adding', 'Adding...')}</>}>
                    <FontAwesomeIcon icon="save" />
                    <> </>
                    {t('addToReenrolmentModal.addSelectedNow.add', 'Add {{count}} selected classes', { count: selectedIds.length })}
                </ButtonAsync>

                <Button type="button" color="primary" outline onClick={() => closeModal({ selectedIds: [], cancelled: true })}>
                    {t('common.cancel', 'Cancel')}
                </Button>
            </ModalFooter>
        </>
    );
};