import gql from "graphql-tag";
import { Guid } from "guid-string";
import { ProfileFieldsFragment } from "../generated/graphql";
import { userFieldsFragment } from "./User";

export const profileFieldsFragment = gql`
    fragment profileFields on Profile {
        id
        userId
        firstName
        lastName
        archived
        primaryEmail
        secondaryEmail
        primaryPhone
        secondaryPhone
        addressLine1
        addressLine2
        city
        county
        postcode
        country
        genderId
        isStaff
        isMember
        oneTimeEmulationCode

        user {
            ...userFields,
        }
    }

    ${userFieldsFragment}
`;


export type Profile = Omit<ProfileFieldsFragment, '__typename' | 'user'>;

/**
 * Default values.
 */
export const profileDefaultValues = (): Partial<Profile> => ({
    id: Guid.newGuid(),
    userId: '',
    firstName: '',
    lastName: '',
    archived: false,
    primaryEmail: '',
    secondaryEmail: '',
    primaryPhone: '',
    secondaryPhone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    postcode: '',
    country: 'Ireland',
    genderId: undefined,
    isMember: false,
    isStaff: false,
    oneTimeEmulationCode: '',
});