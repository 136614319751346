import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, FormGroup, Input, InputGroup, Label } from "reactstrap";
import { useClassTypesForAnonymousUser } from "../../../api/main/classTypes/useClassTypesForAnonymousUser";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../../shared/useURLSearchParams";
import { locations } from "./locations";
import "./classSearchCard.scss";
import { isDevelopmentSite } from "../../shared/isDevelopmentSite/IsDevelopmentSite";

export const ClassSearchCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the classTypes
    const {
        data: {
            items: classTypes
        }, errors: loadErrors
    } = useClassTypesForAnonymousUser();

    // Manage the tab, classType and classLocation being displayed
    const {
        classType: classTypeParam,
        location: locationParam,
        dateOfBirth: dateOfBirthParam
    } = useSearchParams();

    // State for Selected Class Type
    const [selectedClassTypeId, setSelectedClassTypeId] = useState<string>(classTypeParam ?? '');

    // State for Selected Location
    const [selectedLocation, setSelectedLocation] = useState<string>('');
    const [locationCoordinates, setLocationCoordinates] = useState<string>(locationParam ?? '');

    // State for Selected Date of Birth
    const [selectedDateOfBirth, setSelectedDateOfBirth] = useState<string>(dateOfBirthParam ?? '');

    const getUserLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => { setSelectedLocation(`(Current location)`); setLocationCoordinates(`${position.coords.latitude},${position.coords.longitude}`); });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, []);

    // Keep the URL up to date with the filters
    useReplaceSearchParamsEffect({ classType: selectedClassTypeId, dateOfBirth: selectedDateOfBirth, location: locationCoordinates });

    // Limit the date of birth to before today
    const today = useMemo(() => new Date().toISOString().split('T')[0], []);

    const isDevelopment = useMemo(() => isDevelopmentSite(), []);

    // Render the UI
    //
    return (
        <div className="class-search">
            <AlertOnErrors error={loadErrors} />

            <ConditionalFragment showIf={isDevelopment}>
                <Alert color="danger">
                    {t('login.developmentSiteWarning', 'You are on a test site.  The live site can be found')}
                    <> </>
                    <a href="https://members.happycubs.ie" className="alert-link">{t('login.liveSite', 'here.')}</a>
                </Alert>
            </ConditionalFragment>

            <Alert color="warning">
                {t('classSearchCard.warningMessage', 'If you already have an account with us then please us the "Sign in" button at the top right of the screen.')}
            </Alert>

            <Card body className="class-search-card">
                <h1 className="class-search-card-title">{t('classSearchCard.title', 'Find your class')}</h1>

                <p className="class-search-card-subtitle">{t('classSearchCard.subtitle', 'Use the filters below to find the perfect class for your bear cub')}</p>

                <FormGroup className="class-search-card-form-group">
                    <Input className="class-search-card-form-group-select" type="select" value={selectedClassTypeId ?? ''} onChange={e => setSelectedClassTypeId(e.currentTarget.value)}>
                        <option className="class-search-card-form-group-select-option" value="">{t('classSearchCard.allActivities', 'All activities')}</option>
                        {
                            classTypes?.map(classType => (
                                <option className="class-search-card-form-group-select-option" value={classType.id} key={classType.id}>{classType.name}</option>
                            ))
                        }
                    </Input>
                </FormGroup>

                <FormGroup className="class-search-card-form-group">
                    <Label className="class-search-card-form-group-label" htmlFor="location">{t('classSearchCard.yourLocation', 'Your location')}</Label>
                    <InputGroup>
                        <Input list="locations" name="totalTermlyCost" type="text" value={selectedLocation.toString() ?? ''} onChange={e => setSelectedLocation(e.currentTarget.value)} placeholder="Postcode or City..." />
                        <Button color="secondary" onClick={() => getUserLocation()}><FontAwesomeIcon icon="location-pin" color="#fada00" /></Button>
                    </InputGroup>
                    <ConditionalFragment showIf={selectedLocation.length >= 2}>
                        <datalist id="locations">
                            {
                                locations.map((location, index) => (
                                    <option key={index} value={location} />
                                ))
                            }
                        </datalist>
                    </ConditionalFragment>
                </FormGroup>

                <FormGroup className="class-search-card-form-group">
                    <Label className="class-search-card-form-group-label" htmlFor="location">{t('classSearchCard.yourChildsBirthDate', 'Your child\'s birth date')}</Label>
                    <Input min="1900-01-01" max={today} className="class-search-card-form-group-select-dob" name="totalTermlyCost" type="date" value={selectedDateOfBirth} onChange={e => setSelectedDateOfBirth(e.currentTarget.value)} />
                </FormGroup>

                <div className="class-search-card-footer">
                    <Button className="class-search-card-footer-button" color="secondary" disabled={!selectedDateOfBirth || !selectedLocation} onClick={() => selectedClassTypeId ? navigate(`/class-finder?dateOfBirth=${selectedDateOfBirth}&location=${selectedLocation === '(Current location)' ? locationCoordinates : selectedLocation}&classType=${selectedClassTypeId}`) : navigate(`/class-finder?dateOfBirth=${selectedDateOfBirth}&location=${selectedLocation === '(Current location)' ? locationCoordinates : selectedLocation}`)}>
                        {t('classSearchCard.findMyClass', 'Find my class')}
                    </Button>
                </div>
            </Card>
        </div>
    );
};