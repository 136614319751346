import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { JoinScheduledClassWaitingListMutation, JoinScheduledClassWaitingListMutationVariables, JoinScheduledClassResult } from '../generated/graphql';
import { paymentFieldsFragment } from "../models/Payment";
import { scheduledClassChildFieldsFragment } from "../models/ScheduledClassChild";

/**
 * Get a callback to delete a ScheduledClassChild in the api.
 */
export function useJoinScheduledClassWaitingListMutation(): [(childId: string, scheduledClassId: string, isSwappingClass: boolean, oldScheduledClassId?: string | undefined | null) => Promise<JoinScheduledClassResult | null | undefined>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<JoinScheduledClassWaitingListMutation, JoinScheduledClassWaitingListMutationVariables>(
        gql`
            mutation joinScheduledClassWaitingListMutation ($childId: ID!, $scheduledClassId: ID!, $isSwappingClass: Boolean!, $oldScheduledClassId: ID) {
                joinScheduledClassWaitingList(childId: $childId, scheduledClassId: $scheduledClassId, isSwappingClass: $isSwappingClass, oldScheduledClassId: $oldScheduledClassId) {
                    scheduledClassChild {
                        ...scheduledClassChildFields
                    }

                    payment {
                        ...paymentFields
                    }
                }
            }

            ${scheduledClassChildFieldsFragment}
            ${paymentFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (childId: string, scheduledClassId: string, isSwappingClass: boolean, oldScheduledClassId: string | undefined | null) => {
        const result = await mutationAction({ variables: { childId, scheduledClassId, isSwappingClass, oldScheduledClassId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.joinScheduledClassWaitingList as any;
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
