import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { StopReenrolmentViewModelQuery, StopReenrolmentViewModelQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { termFieldsFragment } from '../../models/Term';
import { backgroundTaskFieldsFragment } from '../../models/BackgroundTask';
import { BackgroundTaskName } from '../../models/constants/BackgroundTaskName';

/**
 * Get all model data required for the StopReenrolment component from the API.
 * @param options
 */
export function useStopReenrolmentViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<StopReenrolmentViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<StopReenrolmentViewModelQuery, StopReenrolmentViewModelQueryVariables>(
        gql`
        query stopReenrolmentViewModelQuery ($taskName: String!) {
            nextTerm {
                ...termFields
            }

            latestBackgroundTask(name: $taskName, historyCutoffDays: 366) {
                ...backgroundTaskFields
            }
        }

        ${termFieldsFragment}
        ${backgroundTaskFieldsFragment}
        `,
        {

            variables: {
                taskName: BackgroundTaskName.ProcessStopReenrolmentTask,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
