import { useMutation } from '@apollo/client';
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { GenerateOneTimeEmulationCodeMutation, GenerateOneTimeEmulationCodeMutationVariables } from "../generated/graphql";

/**
 * Get a callback to delete a Profile in the store.
 */
export function useGenerateOneTimeEmulationCodeMutation(): [(id: string) => Promise<{ email: string, code: string }>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<GenerateOneTimeEmulationCodeMutation, GenerateOneTimeEmulationCodeMutationVariables>(
        gql`
            mutation generateOneTimeEmulationCodeMutation ($id: ID!) {
                generateOneTimeEmulationCode(id: $id) {
                    email
                    code
                }
            }
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors?.length) {
            throw result.errors;
        }
        return result.data?.generateOneTimeEmulationCode ?? { email: "", code: "" };
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
