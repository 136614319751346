import { Library, library } from "@fortawesome/fontawesome-svg-core";
import { faClock as farClock, faFrown as farFrown, faSmile as farSmile } from "@fortawesome/free-regular-svg-icons";
import {
    faAtom,
    faComment,
    faAddressCard,
    faBuilding,
    faCalendarDays, 
    faLink, 
    faMasksTheater, 
    faFile, 
    faTags, 
    faNewspaper, 
    faAngleLeft, 
    faAngleRight, 
    faBars, 
    faCaretLeft, 
    faCaretRight, 
    faCogs, 
    faEdit, 
    faEnvelope, 
    faHome, 
    faLock, 
    faPlus,
    faSave,
    faThumbTack,
    faSearch, 
    faSpinner, 
    faThLarge, 
    faThList,
    faTrash, 
    faUser, 
    faUsers, 
    faFolderOpen, 
    faBriefcase, 
    faFolderTree, 
    faKeyboard, 
    faBus, 
    faRoute, 
    faPeopleRoof, 
    faFileLines, 
    faPerson, 
    faBusSimple, 
    faCircleInfo, 
    faUserTie, 
    faBarsProgress, 
    faPencil, 
    faCubes, 
    faMagnifyingGlass, 
    faVideo, 
    faArrowsSpin, 
    faAnglesUp, 
    faCheckDouble, 
    faLockOpen, 
    faUpload,
    faDownload, 
    faTimesCircle, 
    faCaretDown, 
    faEye, 
    faCaretUp, 
    faPhotoVideo, 
    faChevronRight, 
    faPersonChalkboard, 
    faCalendarCheck, 
    faWaterLadder, 
    faSchool, 
    faDrum, 
    faChildReaching, 
    faPersonBreastfeeding, 
    faPersonSwimming, 
    faStopwatch, 
    faClockRotateLeft,
    faMoneyBill1Wave, 
    faPercent, 
    faPeopleGroup, 
    faPersonShelter, 
    faUserGear, 
    faPersonHalfDress, 
    faCalendarXmark, 
    faLayerGroup, 
    faFileExport, 
    faMountainCity, 
    faPeopleArrows, 
    faStamp, 
    faCertificate, 
    faBookOpen, 
    faExclamationTriangle, 
    faTrashAlt, 
    faEuroSign, 
    faRefresh, 
    faFilter, 
    faSliders, 
    faCalendarWeek, 
    faCross, 
    faTimes, 
    faPlane, 
    faSyringe, 
    faCalendar, 
    faInfoCircle, 
    faEyeSlash, 
    faRotateLeft, 
    faClock, 
    faCheck, 
    faEnvelopeCircleCheck, 
    faFileCircleExclamation, 
    faLocationCrosshairs, 
    faMap, 
    faCompass, 
    faChalkboardTeacher, 
    faBarsStaggered,
    faFileMedical, 
    faNoteSticky, 
    faBirthdayCake, 
    faMusic, 
    faSwimmer, 
    faClipboard,
    faArrowLeftLong,
    faArrowRightLong,
    faPeopleLine,
    faChild,
    faReply,
    faMoneyBill,
    faArrowsDownToPeople,
    faPhone,
    faPhoneSlash,
    faGift,
    faChartLine,
    faCirclePlay,
    faHourglass,
    faDatabase,
    faCircleStop,
    faCreditCard,
    faUserPen,
    faCirclePlus,
    faBookOpenReader,
    faMugHot,
    faPersonWalking,
    faGauge,
    faCopy,
    faBullhorn,
    faMinus,
    faMessage,
    faForward,
    faCircle,
    faChevronLeft,
    faDotCircle,
    faShop, 
    faPrint,
    faFileDownload,
    faHouseUser,
    faLocationPin,
    faCircleCheck,
    faCircleMinus,
    faSign,
    faStop
} from "@fortawesome/free-solid-svg-icons";

// Import the fontawsome icons we actually want to use.
export const initializeIcons = () => {
    configureIcons(library);
};

export const configureIcons = (library: Library) => {
    library.add(
        // solid

        faAddressCard,
        faAngleLeft,
        faAngleRight,
        faAnglesUp,
        faArrowLeftLong,
        faArrowRightLong,
        faArrowsDownToPeople,
        faArrowsSpin,
        faAtom,
        faBars,
        faBarsProgress,
        faBarsStaggered,
        faBirthdayCake,
        faBookOpen,
        faBookOpenReader,
        faBriefcase,
        faBuilding,
        faBullhorn,
        faBus,
        faBusSimple,
        faCalendar,
        faCalendarCheck,
        faCalendarDays,
        faCalendarWeek,
        faCalendarXmark,
        faCaretDown,
        faCaretLeft,
        faCaretRight,
        faCaretUp,
        faCertificate,
        faChalkboardTeacher,
        faChartLine,
        faCheck,
        faCheckDouble,
        faChevronLeft,
        faChevronRight,
        faChild,
        faChildReaching,
        faCircle,
        faCircleCheck,
        faCircleInfo,
        faCircleMinus,
        faCirclePlay,
        faCirclePlus,
        faCircleStop,
        faClipboard,
        faClock,
        faClockRotateLeft,
        faCogs,
        faComment,
        faCompass,
        faCopy,
        faCreditCard,
        faCross,
        faCubes,
        faDatabase,
        faDotCircle,
        faDownload,
        faDrum,
        faEdit,
        faEnvelope,
        faEnvelopeCircleCheck,
        faEuroSign,
        faExclamationTriangle,
        faEye,
        faEyeSlash,
        faFile,
        faFileCircleExclamation,
        faFileDownload,
        faFileExport,
        faFileLines,
        faFileMedical,
        faFilter,
        faFolderOpen,
        faFolderTree,
        faForward,
        faGauge,
        faGift,
        faHome,
        faHourglass,
        faHouseUser,
        faInfoCircle,
        faKeyboard,
        faLayerGroup,
        faLocationPin,
        faLink,
        faLocationCrosshairs,
        faLock,
        faLockOpen,
        faMagnifyingGlass,
        faMap,
        faMessage,
        faMinus,
        faMoneyBill,
        faMoneyBill1Wave,
        faMountainCity,
        faMugHot,
        faMusic,
        faNewspaper,
        faNoteSticky,
        faPencil,
        faPeopleArrows,
        faPeopleGroup,
        faPeopleLine,
        faPeopleRoof,
        faPercent,
        faPersonBreastfeeding,
        faPersonChalkboard,
        faPersonHalfDress,
        faPersonShelter,
        faPersonSwimming,
        faPersonWalking,
        faPhone,
        faPhoneSlash,
        faPhotoVideo,
        faPlane,
        faPlus,
        faPrint,
        faRefresh,
        faReply,
        faRotateLeft,
        faRoute,
        faSave,
        faSchool,
        faSearch,
        faShop,
        faSign,
        faSliders,
        faSpinner,
        faStamp,
        faStop,
        faStopwatch,
        faSwimmer,
        faSyringe,
        faTags,
        faThLarge,
        faThList,
        faThumbTack,
        faTimes,
        faTimesCircle,
        faTrash,
        faTrashAlt,
        faUpload,
        faUser,
        faUserGear,
        faUserPen,
        faUsers,
        faUserTie,
        faVideo,
        faWaterLadder,
        faPerson, faMasksTheater,

        // regular
        farFrown,
        farClock,
        farSmile, 
    );
};
