import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useMemo } from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { StaffAbsenceViewModelQuery, StaffAbsenceViewModelQueryVariables } from "../../generated/graphql";
import { staffAbsenceFieldsFragment } from "../../models/StaffAbsence";
import { staffAbsenceTypeFieldsFragment } from "../../models/StaffAbsenceType";

/**
 * Get all data required for the StaffAbsencViewModel component from the api.
 * @param options
 */
export function useStaffAbsenceViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<StaffAbsenceViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<StaffAbsenceViewModelQuery, StaffAbsenceViewModelQueryVariables>(
        gql`
        query staffAbsenceViewModelQuery {
            staffAbsenceTypes {
                ...staffAbsenceTypeFields
            }

            staffAbsences {
                ...staffAbsenceFields
            }

            profiles {
                id
                userId
                firstName
                lastName
                archived
                primaryEmail
                secondaryEmail
                primaryPhone
                secondaryPhone
                addressLine1
                addressLine2
                city
                county
                postcode
                country
                genderId
                isStaff
                isMember
                oneTimeEmulationCode
            }
        }

        ${staffAbsenceFieldsFragment}
        ${staffAbsenceTypeFieldsFragment}
        `,
        {
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};