import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { scheduledClassFieldsFragment } from '../../models/ScheduledClass';
import { ScheduledClassesForStopReenrolmentsViewModelQuery, ScheduledClassesForStopReenrolmentsViewModelQueryVariables } from '../../generated/graphql';
import { classTypeFieldsFragment } from '../../models/ClassType';
import { classStageFieldsFragment } from '../../models/ClassStage';
import { classSubStageFieldsFragment } from '../../models/ClassSubStage';
import { classLocationFieldsFragment } from '../../models/ClassLocation';
import { termFieldsFragment } from '../../models/Term';

export interface ScheduledClassesForStopReenrolmentsViewModelAsyncLoadPagedOptions extends AsyncLoadPagedOptions {
    ids?: Array<string>,
}

/**
 * Get all data required for the ScheduledClassesList component from the api.
 * @param options
 */
export function useScheduledClassesForStopReenrolmentsViewModel(options: ScheduledClassesForStopReenrolmentsViewModelAsyncLoadPagedOptions = {}): AsyncLoadPagedResult<ScheduledClassesForStopReenrolmentsViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<ScheduledClassesForStopReenrolmentsViewModelQuery, ScheduledClassesForStopReenrolmentsViewModelQueryVariables>(
        gql`
        query scheduledClassesForStopReenrolmentsViewModelQuery ($offset: Int, $first: Int, $ids: [ID!]) {
            items: scheduledClasses (offset: $offset, first: $first, ids: $ids) {
                ...scheduledClassFields
            }

            classTypes {
                ...classTypeFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classLocations {
                ...classLocationFields
            }

            terms (restrictToTermsWithClasses: true) {
                ...termFields
            }
        }

        ${scheduledClassFieldsFragment}
        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classLocationFieldsFragment}
        ${termFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                ids: options.ids
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<ScheduledClassesForStopReenrolmentsViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            classTypes: newResults?.classTypes ?? [],
            classStages: newResults?.classStages ?? [],
            classSubStages: newResults?.classSubStages ?? [],
            classLocations: newResults?.classLocations ?? [],
            terms: newResults?.terms ?? [],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}