import { useLazyQuery } from "@apollo/client";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import gql from "graphql-tag";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { useCallback, useEffect, useMemo } from "react";
import { EditChildSupportingDataQuery } from '../../generated/graphql';
import { childsRelationshipFieldsFragment } from "../../models/ChildsRelationship";
import { genderFieldsFragment } from "../../models/Gender";
import { termFieldsFragment } from "../../models/Term";
 
/**
 * Get the supporting data needed for dropdowns etc for the EditChild (Children) component
 * @param id 
 * @param options
 */
export function useEditChildSupportingData(options: AsyncLoadOptions = {}): AsyncLoadResult<EditChildSupportingDataQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditChildSupportingDataQuery>(
        gql`
            query editChildSupportingDataQuery ($offset: Int, $first: Int) {
                memberProfiles: profiles (isMember: true) {
                    id
                    userId
                    firstName
                    lastName
                    archived
                    primaryEmail
                    secondaryEmail
                    primaryPhone
                    secondaryPhone
                    addressLine1
                    addressLine2
                    city
                    county
                    postcode
                    country
                    genderId
                    isStaff
                    isMember
                    oneTimeEmulationCode
                
                    user {
                      id
                      email
                    }
                }

                staffProfiles: profiles (isStaff: true) {
                    id
                    userId
                    firstName
                    lastName
                    archived
                    primaryEmail
                    secondaryEmail
                    primaryPhone
                    secondaryPhone
                    addressLine1
                    addressLine2
                    city
                    county
                    postcode
                    country
                    genderId
                    isStaff
                    isMember
                    oneTimeEmulationCode
                
                    user {
                        id
                        email
                    }
                }

                childsRelationships (offset: $offset, first: $first) {
                    ...childsRelationshipFields
                }

                genders {
                    ...genderFields
                }

                terms {
                    ...termFields
                }
            }

        ${childsRelationshipFieldsFragment}
        ${genderFieldsFragment}
        ${termFieldsFragment}
        `,
{
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}