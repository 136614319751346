import { TFunction } from "i18next";


/**
 * Name of a background task.  Keep this matched up with BackgroundTasks in C#.
 */
export enum BackgroundTaskName {
    ProcessReenrolmentTask = "ProcessReenrolmentTask",
    SendReenrolmentFinalReminderTask = "SendReenrolmentFinalReminderTask",
    SendReenrolmentReminderTask = "SendReenrolmentReminderTask",
    SendGraduationEmailTask = "SendGraduationEmailTask",
    ProcessScheduledClassCancellationTask = "ProcessScheduledClassCancellationTask",
    ProgressClassStagesTask = "ProgressClassStagesTask",
    ProgressLessonNumberForScheduledClassesThanRanTask = "ProgressLessonNumberForScheduledClassesThanRanTask",
    InitializeStandardTasksTask = "InitializeStandardTasksTask",
    ArchiveScheduledClassChildrenWithAPastLastLessonDateTask = "ArchiveScheduledClassChildrenWithAPastLastLessonDateTask",
    ProcessCourtesyClassCreditsAtEndOfTermTask = "ProcessCourtesyClassCreditsAtEndOfTermTask",
    ProcessScheduledClassSubstituteTeachersTask = "ProcessScheduledClassSubstituteTeachersTask",
    ArchiveNoticeBoardItemsPastTheirEndDateTask = "ArchiveNoticeBoardItemsPastTheirEndDateTask",
    ArchiveSelfServiceScheduledClassChildrenIfNotPaidTask = "ArchiveSelfServiceScheduledClassChildrenIfNotPaidTask",
    ProcessStopReenrolmentTask = "ProcessStopReenrolmentTask",
}

/**
 * Returns all BackgroundTaskName options, suitable for use in a list.
 */
export function getBackgroundTaskName(): Array<BackgroundTaskName> {
    const ret = Object.keys(BackgroundTaskName).map(key => key as BackgroundTaskName);
    return ret;
}

/**
 * Convert a BackgroundTaskName to its display name. 
 */
export function backgroundTaskNameDisplayName(name: BackgroundTaskName | string, t: TFunction): string {
    switch (name) {
        case BackgroundTaskName.ProcessReenrolmentTask: return t('backgroundTaskName.processReenrolmentTask.name', 'Process re-enrolement for a term');
        case BackgroundTaskName.SendReenrolmentFinalReminderTask: return t('backgroundTaskName.sendReenrolmentFinalReminderTask.name', 'Send reminders for people who haven\'t completed re-enrolement');
        case BackgroundTaskName.SendReenrolmentReminderTask: return t('backgroundTaskName.sendReenrolmentReminderTask.name', 'Send final reminders for people who haven\'t completed re-enrolement');
        case BackgroundTaskName.SendGraduationEmailTask: return t('backgroundTaskName.sendGraduationEmailTask.name', 'Send graduation emails');
        case BackgroundTaskName.ProcessScheduledClassCancellationTask: return t('backgroundTaskName.processScheduledClassCancellationTask.name', 'Send emails/SMS for class cancellations');
        case BackgroundTaskName.ProgressClassStagesTask: return t('backgroundTaskName.progressClassStagesTask.name', 'Progress all class stages and substages at the end of a term');
        case BackgroundTaskName.ProgressLessonNumberForScheduledClassesThanRanTask: return t('backgroundTaskName.progressLessonNumberForScheduledClassesThanRanTask.name', 'Progress lesson numbers for classes that ran');
        case BackgroundTaskName.InitializeStandardTasksTask: return t('backgroundTaskName.initializeStandardTasksTask.name', 'Schedule all standard tasks');
        case BackgroundTaskName.ArchiveScheduledClassChildrenWithAPastLastLessonDateTask: return t('backgroundTaskName.archiveScheduledClassChildrenWithAPastLastLessonDateTask.name', 'Remove any children from classes that have past their last lesson date');
        case BackgroundTaskName.ProcessCourtesyClassCreditsAtEndOfTermTask: return t('backgroundTaskName.processCourtesyClassCreditsAtEndOfTermTask.name', 'Remove any courtesy class credits at the end of term');
        case BackgroundTaskName.ProcessScheduledClassSubstituteTeachersTask: return t('backgroundTaskName.processScheduledClassSubstituteTeachersTask.name', 'Add / remove substitute teachers from scheduled classes, based on the dates assigned');
        case BackgroundTaskName.ArchiveNoticeBoardItemsPastTheirEndDateTask: return t('backgroundTaskName.archiveNoticeBoardItemsPastTheirEndDateTask.name', 'Archive notice board items that have past their end date');
        case BackgroundTaskName.ArchiveSelfServiceScheduledClassChildrenIfNotPaidTask: return t('backgroundTaskName.archiveSelfServiceScheduledClassChildrenIfNotPaidTask.name', 'Archive self-service scheduled class children if they haven\'t paid and 10 minutes has elapsed');
        case BackgroundTaskName.ProcessStopReenrolmentTask: return t('backgroundTaskName.processStopReenrolmentTask.name', 'Close re-enrolement for a term');

        default: return name;
    }
}
